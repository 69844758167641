import LabelLink from "../LabelLink";
import TopHeading from "./TopHeading";
import LabelList from "../LabelList";
import type {
  SearchAreaCategory,
  SearchAreaCategories,
  SearchArea,
  Masters,
} from "~/types";

const SearchByArea = ({ masters }: { masters: Masters }) => {
  const searchAreaCategories = masters.searchAreaCategories.reduce(
    (acc: SearchAreaCategories, category: SearchAreaCategory) => {
      acc[category.key] = category;
      return acc;
    },
    {}
  );
  const searchAreas = masters.searchAreas;
  const searchAreasForTokyo = searchAreas.filter(
    (area: SearchArea) =>
      area.search_area_category_id === searchAreaCategories["tokyo"]["id"]
  );
  const searchAreaIdsForTokyo = searchAreasForTokyo
    .map((area: SearchArea) => area.id)
    .join(",");
  const searchAreasForKanto = searchAreas.filter(
    (area: SearchArea) =>
      area.search_area_category_id === searchAreaCategories["kanto"]["id"]
  );
  const searchAreasForKansai = searchAreas.filter(
    (area: SearchArea) =>
      area.search_area_category_id === searchAreaCategories["kansai"]["id"]
  );
  const searchAreasForSearchAreaCategoryOther = searchAreas.filter(
    (area: SearchArea) =>
      area.search_area_category_id ===
      searchAreaCategories["search-area-category-other"]["id"]
  );

  // クエリパラメーターを生成
  const generateQueryParams = (searchAreaId: string) => {
    const params = new URLSearchParams();
    // NOTE: 本コンポーネントでは単一のidを渡す
    // NOTE: クエリパラメータの受取は複数のidの受取に対応しているためキー名が複数形
    // NOTE: 下記のキー名と変数名にズレが有るように思えるが問題ない
    params.append("search_area_ids", searchAreaId);
    return params.toString();
  };

  return (
    <section className="my-20 px-4 md:my-[104px] md:px-7">
      <div className="md:max-w-[1040px] md:mx-auto">
        <TopHeading
          text="エリアから探す"
          icon="pin"
          iconBg="tertiary"
        ></TopHeading>
        <div>
          <LabelList>
            <dt>{searchAreaCategories["tokyo"]["name"]}</dt>
            <dd>
              <LabelLink
                linkUrl={`/list/?${generateQueryParams(searchAreaIdsForTokyo)}`}
                text="東京都全域"
                labelColor="tertiary"
              ></LabelLink>
              {searchAreasForTokyo.map((area: SearchArea) => (
                <LabelLink
                  key={area.id}
                  linkUrl={`/list/?${generateQueryParams(area.id)}`}
                  text={area.name}
                  labelColor="tertiary"
                ></LabelLink>
              ))}
            </dd>
          </LabelList>
          <LabelList>
            <dt>{searchAreaCategories["kanto"]["name"]}</dt>
            <dd>
              {searchAreasForKanto.map((area: SearchArea) => (
                <LabelLink
                  key={area.id}
                  linkUrl={`/list/?${generateQueryParams(area.id)}`}
                  text={area.name}
                  labelColor="tertiary"
                ></LabelLink>
              ))}
            </dd>
          </LabelList>
          <LabelList>
            <dt>{searchAreaCategories["kansai"]["name"]}</dt>
            <dd>
              {searchAreasForKansai.map((area: SearchArea) => (
                <LabelLink
                  key={area.id}
                  linkUrl={`/list/?${generateQueryParams(area.id)}`}
                  text={area.name}
                  labelColor="tertiary"
                ></LabelLink>
              ))}
            </dd>
          </LabelList>
          <LabelList>
            <dt>
              {searchAreaCategories["search-area-category-other"]["name"]}
            </dt>
            <dd>
              {searchAreasForSearchAreaCategoryOther.map((area: SearchArea) => (
                <LabelLink
                  key={area.id}
                  linkUrl={`/list/?${generateQueryParams(area.id)}`}
                  text={area.name}
                  labelColor="tertiary"
                ></LabelLink>
              ))}
            </dd>
          </LabelList>
        </div>
      </div>
    </section>
  );
};

export default SearchByArea;
