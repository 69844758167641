import React from 'react'; // TODO: あれ？ import { useLoaderData } from "@remix-run/react";じゃないか？
import Contact1Button from './Contact1Button';
import Contact1Item from './Contact1Item';
import DirectSearchModal from '../search/DirectSearchModal';
import { useTransition } from 'react-transition-state';
import type { Masters, Condition } from '~/types';
import { useEnvironment } from '~/context/EnvironmentContext';

type Props = { masters: Masters };

const initialState: Condition = {
  occupation_type_ids: [],
  search_area_ids: [],
  service_type_ids: [],
};

const Contact1: React.FC<Props> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [number, setNumber] = React.useState(0);
  const masters = props.masters;

  const [selectedConditions, setSelectedConditions] =
    React.useState<Condition>(initialState);

  const searchStateToggle = () => {
    setVisible(!visible);
    setNumber(0);
    transitionToggle(true);
  };

  // モーダル開閉アニメーション用
  const [{ status }, transitionToggle] = useTransition({
    timeout: 1,
    preEnter: true,
  });

  // 検索条件の追加
  const handleAddCondition = (
    type: keyof Condition,
    value: string | string[]
  ) => {
    setSelectedConditions((prevConditions) => {
      const newValue = Array.isArray(value) ? value : [value];
      const updateValue = newValue.filter(
        (id) => !prevConditions[type].includes(id)
      );
      return {
        ...prevConditions,
        [type]: [...prevConditions[type], ...updateValue],
      };
    });
  };

  // 検索条件の削除
  const handleSubCondition = (
    type: keyof Condition,
    value: string | string[]
  ) => {
    setSelectedConditions((prevConditions) => {
      const newValues = Array.isArray(value) ? value : [value];
      return {
        ...prevConditions,
        [type]: prevConditions[type].filter((id) => !newValues.includes(id)),
      };
    });
  };

  // 検索条件のリセット
  const handleResetConditions = () => {
    setSelectedConditions(initialState);
  };

  // 閉じる（x）ボタンクリック時の挙動
  const handleVisibleToFalseForClose = () => {
    transitionToggle(false);
    setTimeout(() => {
      setVisible(false);
    }, 1000);
  };

  // コンテキストから静的画像バケットのエンドポイントを取得
  const { staticImageBucketEndpoint } = useEnvironment();

  // NOTE: className周りでうまく画像取得できない問題があり、環境関係なく本番のR2を見に行くようにしているためコメントアウト
  // 画像URLを変数に格納（classNameで呼び出すため事前に変数に格納）
  //const bgGreenImageDesktopURL = `url(${staticImageBucketEndpoint}/public/top/contact1-bg-green_pc.webp)`;
  //const bgGreenImageMobileURL = `url(${staticImageBucketEndpoint}/public/top/contact1-bg-green_sp.webp)`;
  //const bgOrangeImageDesktopURL = `url(${staticImageBucketEndpoint}/public/top/contact1-bg-orange_pc.webp)`;
  //const bgOrangeImageMobileURL = `url(${staticImageBucketEndpoint}/public/top/contact1-bg-orange_sp.webp)`;

  return (
    <>
      <div className="md:my-[104px] md:px-7">
        <div className="md:max-w-[1040px] md:mx-auto">
          <section
            className={`py-14 px-4 bg-[url(https://public.fjob-kyujin.com/public/top/contact1-bg-orange_sp.webp)] bg-[length:480px_auto] bg-repeat md:pt-12 md:pb-[30px] md:px-10 md:bg-[url(https://public.fjob-kyujin.com/public/top/contact1-bg-orange_pc.webp)] md:bg-[length:1110px_auto] md:rounded-3xl`}
          >
            <div className="mb-8 text-center md:mb-10">
              <h2 className="relative inline-block text-white text-[28px] leading-normal font-black md:text-[40px] md:leading-[1.3]">
                あなたに合うやり方で、
                <br />
                転職を始めよう。
                <span className="absolute -top-5 -right-3 w-9 md:-top-5 md:-right-4 md:w-10">
                  <img
                    src={`${staticImageBucketEndpoint}/public/top/contact1-accent.webp`}
                    width="60"
                    height="60"
                    alt=""
                  />
                </span>
              </h2>
            </div>
            <div className="grid grid-cols-1 gap-6 md:gap-4">
              <Contact1Item
                imgUrl={`${staticImageBucketEndpoint}/public/top/contact1-img-fjob-1.webp`}
                color="primary"
                titleStrong="「自分に合う仕事がわからない」"
                titleSmall="というあなたに。"
              >
                <p>
                  キャリアアドバイザーがあなたに合った求人情報をご紹介！
                  <br />
                  あなたの転職活動を全力でサポートいたします。
                </p>
                <div className="mt-4 text-center md:mt-5">
                  <Contact1Button
                    id="fjob_cv"
                    color="primary"
                    text="エフジョブのサイトへ"
                    icon="exlink"
                    linkUrl="https://www.fs-job.net/"
                    isTargetBlank={true}
                  ></Contact1Button>
                </div>
              </Contact1Item>
              <Contact1Item
                imgUrl={`${staticImageBucketEndpoint}/public/top/contact1-img-fjob-2.webp`}
                color="primary"
                titleStrong="「今すぐ求人を見たい！」"
                titleSmall="というあなたに。"
              >
                <p>
                  求人検索から気になる求人をチェック！
                  <br className="md:hidden" />
                  ぜひお問い合わせください。
                </p>
                <div className="mt-4 text-center md:mt-5">
                  <Contact1Button
                    color="primary"
                    text="求人検索する"
                    icon="search"
                    handler={searchStateToggle}
                  ></Contact1Button>
                </div>
              </Contact1Item>
            </div>
          </section>
          <section
            className={`py-14 px-4 bg-[url(https://public.fjob-kyujin.com/public/top/contact1-bg-green_sp.webp)] bg-[length:480px_auto] bg-repeat md:mt-20 md:pt-12 md:pb-[30px] md:px-10 md:bg-[url(https://public.fjob-kyujin.com/public/top/contact1-bg-green_pc.webp)] md:bg-[length:1110px_auto] md:rounded-3xl`}
          >
            <div className="mb-8 text-center">
              <h2 className="relative inline-block text-white text-[28px] leading-normal font-black md:text-[40px] md:leading-[1.3]">
                LINE公式
                <br className="md:hidden" />
                アカウントはこちら
                <span className="absolute top-6 -right-7 w-9 md:-top-5 md:-right-9 md:w-10">
                  <img
                    src={`${staticImageBucketEndpoint}/public/top/contact1-accent.webp`}
                    width="60"
                    height="60"
                    alt=""
                  />
                </span>
              </h2>
            </div>
            <div className="grid grid-cols-1 gap-6 [&_h5_br]:hidden">
              <Contact1Item
                imgUrl={`${staticImageBucketEndpoint}/public/top/contact1-img-line.webp`}
                color="green"
                titleStrong="「気になる求人情報があれば転職を考えたい」"
                titleSmall="というあなたに。"
              >
                <p>
                  LINE公式アカウントをお友達に追加していただくと、あなたにピッタリの求人情報が定期的に届きます。
                </p>
                <div className="mt-4 text-center">
                  <Contact1Button
                    id="line_cv"
                    color="green"
                    text="LINEでお友達登録"
                    icon="exlink"
                    linkUrl="https://liff.line.me/2001887498-qlJngzxn/landing?follow=%40qyf0197v&lp=M3Sz1b&liff_id=2001887498-qlJngzxn"
                    isTargetBlank={true}
                  ></Contact1Button>
                </div>
              </Contact1Item>
            </div>
          </section>
        </div>
      </div>
      <div className={`group ${status}`}>
        {visible && (
          <DirectSearchModal
            setVisible={setVisible}
            activeTab={number}
            masters={masters}
            handleAddCondition={handleAddCondition}
            handleSubCondition={handleSubCondition}
            handleResetConditions={handleResetConditions}
            selectedConditions={selectedConditions}
            setSelectedConditions={setSelectedConditions}
            initialState={initialState}
            handleVisibleToFalseForClose={handleVisibleToFalseForClose}
          ></DirectSearchModal>
        )}
      </div>
    </>
  );
};

export default Contact1;
