import { useEnvironment } from "~/context/EnvironmentContext";

const AboutUs = () => {
  // コンテキストから静的画像バケットのエンドポイントを取得
  const { staticImageBucketEndpoint } = useEnvironment();

  return (
    <section
      id="sect-about"
      className="px-4 py-14 bg-gray-light md:pt-18 md:px-7 md:pb-20"
    >
      <div className="max-w-[1040px] mx-auto">
        <div className="sp:px-2">
          <div className="mb-1">
            <h2 className="relative inline-block pt-1.5 pr-3 pb-1.5 pl-1.5 bg-white text-[15px] text-primary font-black rounded shadow-[1px_1px_6px_0_rgba(51,51,51,0.10)] md:text-lg">
              『エフジョブ求人』とは？
              <svg className="absolute -top-2 -left-3 aspect-square w-[22px] fill-primary md:-top-2.5 md:-left-4 md:w-7">
                <use xlinkHref="/symbol-defs.svg#icon-quote"></use>
              </svg>
            </h2>
          </div>
          <p className="mb-4 font-black text-lg leading-normal md:text-[26px] md:leading-normal">
            『エフジョブ求人』は、
            <strong className="text-primary text-2xl leading-normal md:text-5xl md:leading-normal">
              飲食業界への転職を希望する方と企業をマッチングするサービス
            </strong>
            です。
          </p>
          <p className="text-[15px] font-medium leading-[1.8] md:text-base md:leading-[1.8]">
            キャリアアドバイザーが求職者の皆様の状況やご要望などをヒアリングし、スタートから内定までサポートいたします。
            <br />
            飲食業界に特化している為、業界に関する情報やネットワークに自信があります。
          </p>
        </div>
        <figure className="block mt-6 pt-4 px-4 pb-6 bg-white rounded-lg shadow-[1px_1px_6px_0_rgba(51,51,51,0.10)] text-center md:mt-8 md:pt-10 md:pb-12">
          <span className="inline-block max-w-[708px]">
            <img
              src={`${staticImageBucketEndpoint}/public/top/aboutus-img.webp`}
              width="708"
              height="254"
              alt=""
            />
          </span>
        </figure>
      </div>
    </section>
  );
};

export default AboutUs;
